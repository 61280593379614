
html { height: 100%; }
body {
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: border-box;
  min-height:100%; 
  position:relative; 
    padding-bottom:[footer-height] 
}

.header {
  background-color: #fff !important;
  box-shadow: 0px 10px 16px -4px rgba(0, 0, 0, 0.1);
  position: relative;
}
.header .navbar-collapse {
  flex-grow: 0;
}
.header .logo_text {
  font-size: 36px;
  font-weight: 600;
  color: #023347;
}
.header .navbar-nav .nav-item .nav-link {
  font-size: 18px;
  font-weight: 700;
  color: #053961;
  cursor: pointer;
}
.nav-link.active {
  border-bottom: 1px solid #053961;
}
.cart_icon{
 position: relative;
}
.cart_icon a{
  font-size: 25px;
}
.Login_but{
  background-color: #f80000;
  border: 1px solid #fff;
  border-radius: 20px;
  text-decoration: none;
  color: #fff;
  top: 15px;
  font-weight: bold;
  right: 70px;
  font-size: 16px;
  padding: 0px 15px;
}
.cart_icon span{
  background-color: #e61919;
  border: 1px solid #fff;
  border-radius: 20px;
  text-decoration: none;
  color: #fff;
  position: absolute;
  top: -20px;
  right: -20px;
  font-size: 16px;
  padding: 0px 5px;
}
.add_to_cart button{
  background-color: #fff;
  border: 1px solid #053961;
  color: #053961;
  font-size: 15px;
  font-weight: 500;
  margin: 5px;
  padding: 8px;
}

.add_to_cart button:hover{
  background-color: #053961;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  border: 1px solid #fff;
}

/* Home_Page_background/animation */

.home-main {
  background-image: url("../images/home/home_bg_1.png");
  min-height: 506px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  position: relative;
}
.home-main::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: #19284f;
  opacity: 90%;
  width: 100%;
  height: 100%;
}
.home-main::after {
  content: "";
  position: absolute;
  bottom: -100px;
  left: 50%;
  width: 450px;
  height: 450px;
  border-radius: 265px;
  transform: translate(-50%, 0) !important;
  opacity: 1;
  background-color: #ffffff;
  animation: bg_white 1s 1s 1 normal;
}
.street{
  background-color: #7a7a7a;
  height: 80px;
  width: 100px;
  /* position: absolute; */
}
.car_img {
  position: absolute;
  bottom: -10%;
  left: 50%;
  width: 350px;
  transform: translate(-50%, 0%) !important;
  z-index: 1;
  opacity: 0;
  animation: car 1s 1s 1 normal forwards;
  animation-delay: 2s;
}
.car_img::after{
  content: '';
  position: relative;
  background-color: #7a7a7a;

}
.circle_1 {
  position: absolute;
  bottom: 5%;
  left: 24%;
  width: 160px;
  height: 160px;
  z-index: 1;
  background-color: #fff;
  border-radius: 50%;
  padding: 10px;
  border: 6px solid #003471;
  opacity: 0;
  animation: circle_1 1s 1s 1 normal forwards;
  animation-delay: 3s;
}
.circle_2 {
  position: absolute;
  top: 25%;
  left: 28%;
  width: 160px;
  height: 160px;
  z-index: 1;
  background-color: #fff;
  border-radius: 50%;
  padding: 10px;
  border: 6px solid #003471;
  opacity: 0;
  animation: circle_2 1s 2s 1 normal forwards;
  animation-delay: 4s;
}
.circle_3 {
  position: absolute;
  top: 5%;
  left: 44%;
  width: 160px;
  height: 160px;
  z-index: 1;
  background-color: #fff;
  border-radius: 50%;
  padding: 10px;
  border: 6px solid #003471;
  opacity: 0;
  animation: circle_3 1s 3s 1 normal forwards;
  animation-delay: 5s;
}
.circle_4 {
  position: absolute;
  top: 25%;
  right: 28%;
  width: 160px;
  height: 160px;
  z-index: 1;
  background-color: #fff;
  border-radius: 50%;
  padding: 10px;
  border: 6px solid #003471;
  opacity: 0;
  animation: circle_4 1s 4s 1 normal forwards;
  animation-delay: 6s;
}
.circle_5 {
  position: absolute;
  bottom: 5%;
  right: 24%;
  width: 160px;
  height: 160px;
  z-index: 1;
  background-color: #fff;
  border-radius: 50%;
  padding: 10px;
  border: 6px solid #003471;
  opacity: 0;
  animation: cricle_5 1s 5s 1 normal forwards;
  animation-delay: 7s;
}
.circle_inner {
  /* background-image: url("../images/circle_inner.png"); */
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 1s;
  position: relative;
  /* animation: cir_inner 9s  linear; */

}
.circle_inner .menu_link {
  position: absolute;
  font-size: 20px;
  color: #003471;
  font-weight: 700;
  text-align: center;
  z-index: 99;
  text-decoration: none;
}
.circle_1 .circle_inner img{
  animation: cir_inner 3s infinite linear;
  animation-delay: 1s;
  opacity: 0.3;
}
.circle_2 .circle_inner img{
  animation: cir_inner 3s infinite linear;
  animation-delay: 2s;
  opacity: 0.3;
}
.circle_3 .circle_inner img{
  animation: cir_inner 3s infinite linear;
  animation-delay: 3s;
  opacity: 0.3;
}
.circle_4 .circle_inner img{
  animation: cir_inner 3s infinite linear;
  animation-delay: 4s;
  opacity: 0.3;
}
.circle_5 .circle_inner img{
  animation: cir_inner 3s infinite linear;
  animation-delay: 5s;
  opacity: 0.3;
}
.sec_main {
  padding: 50px;
  text-align: center;
}
.sec_title h4 {
  font-size: 30px;
  line-height: 48px;
  color: #000000;
  font-weight: 700;
  position: relative;
}
.sec_title h4::after {
  content: " ";
  position: absolute;
  width: 150px;
  height: 10px;
  background-color: #003471;
  top: 50px;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 5px;
}
.sec_subtitle p {
  padding: 30px 0 20px;
  font-size: 20px;
  line-height: 33px;
  color: #767980;
  font-weight: 400;
}

/* Car_services_Provider */
.list_box_cars {
  padding: 30px;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 14px;
}
.list_box_cars img {
  height: 86px;
}
.list_box_text{
  min-height: 120px;
  /* text-overflow: ellipsis */
}
.list_box_text h5 {
  font-size: 20px;
  line-height: 33px;
  color: #767980;
  font-weight: 400;
  margin-bottom: 0% !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3; 
  -webkit-box-orient: vertical;
}
.list_box_text p {
  color: red;
  font-size: 20px;
  line-height: 33px;
  font-weight: 400;
  margin-bottom: 0% !important;
}

/* visited_categories */
.list_box {
  padding: 15px;
  background-color: #fff;
  border: 1px solid black;
}
.list_text h5,
.list_text p {
  font-size: 20px;
  line-height: 28px;
  color: #767980;
  font-weight: 700;
  margin-bottom: 0 !important;
}

/* car_buy/sell_provider */
.list_box_cars_provider {
  padding: 15px;
  margin: 0%;
  background-color: #fff;
}

/* car_wash_provider */
.list_box_cars_wash {
  padding: 30px;
  margin-bottom: 20px;
  background-color: #fff;
}
.list_box_cars_wash img{
  height: 86px;
}
.list_car_wash_text{
  min-height: 120px;
}
.list_car_wash_text h5 {
  font-size: 20px;
  line-height: 33px;
  color: #767980;
  font-weight: 400;
  margin-bottom: 0% !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3; 
  -webkit-box-orient: vertical;
}
.list_car_wash_text p {
  color: red;
  font-size: 20px;
  line-height: 33px;
  font-weight: 400;
  margin-bottom: 0% !important;
}

/* car_accessories */
.list_box_cars_accessories{
  padding: 30px;
  margin-bottom: 20px;
  background-color: #fff;
}
.list_box_cars_accessories img{
  height: 86px;
}
.list_car_access_text{
  min-height: 150px;
}
.list_car_access_text h5 {
  font-size: 20px;
  line-height: 33px;
  color: #767980;
  font-weight: 400;
  margin-bottom: 0% !important;
  overflow: hidden;

}
.list_car_access_text p {
  color: red;
  font-size: 20px;
  line-height: 33px;
  font-weight: 400;
  margin-bottom: 0% !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1; 
  -webkit-box-orient: vertical;
}

/* Login Form */
.login_main{
  padding: 80px;
}
.login_form{
  box-shadow: 0 2px 10px rgb(16 85 153 / 50%);
  padding: 30px;
  border-radius: 20px;
}
.login_form h2{
  font-size: 30px;
  font-weight: 700;
  color: #053961;
  margin-bottom: 15px;
}


/* Dowload_app */
.phone_app {
  display: flex;
  justify-content: space-evenly;
}
.download_app_text h5 {
  font-size: 24px;
  line-height: 30px;
  color: #000000;
  font-weight: 700;
  max-width: 500px;
  text-align: left;
}
.download_app_img {
  display: flex;
}

/* footer */
.welcome_footer{
  position: fixed;
  width: 100%;
  bottom: 0;
}

/* .login .footer{
  position:absolute;
  bottom: 0;
  left: 0;
  width: 100%;
} */

.footer {
  
  margin: 0;
  bottom: 0;
  padding: 20px;
  background-image: linear-gradient(255deg, #11559b 0%, #77a4d1 100%);
  border: 1px solid #000000;
}

/* social-icons */
.social_icons ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.social_icons li {
  margin: 0 10px;
}
.social_icons li a {
  padding: 10px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #fff;
  transition: all 0.5s ease-in-out;
}
.social_icons li a:hover {
  background-color: #fff;
  color: #165aa1;
}

/* loader */
.spinner {
  width: 200px;
  height: 200px;
  border: 8px solid;
  align-items: center;
  justify-content: center;
  align-content: center;
  border-color:	#000000 transparent 	#000000 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
  text-align: center;
  margin: 0 auto;
}

@keyframes spin-anim {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

@keyframes bg_white{
  0%{
    bottom:-100px; left: 50%; width: 50px; height: 50px; transition: 0.5s all ease-in; opacity: 1;
  }
  25%{
    bottom:-100px; left: 50%; width: 150px; height: 150px; transition: 0.5s all ease-in; opacity: 1; 
  }
  50%{
    bottom:-100px; left: 50%; width: 250px; height: 250px; transition: 0.5s all ease-in; opacity: 1;
  }
  75%{
    bottom:-100px; left: 50%; width: 350px; height: 350px; transition: 0.5s all ease-in; opacity: 1;
  }
  100% {
    bottom:-100px; left: 50%; width: 450px; height: 450px; transition: 0.5s all ease-in; opacity: 1;
  }
}

@keyframes car {
  0%{
    top:28%;left: 50%;width: 80px;transition: 0.5s all ease-in;opacity: 1;
  }
  25%{
    top:30%;left: 50%;width: 110px;transition: 0.5s all ease-in;opacity: 1;
  }
  50%{
    top:40%;left: 50%;width: 150px;transition: 0.5s all ease-in;opacity: 1;
  }
  75%{
    top:50%;left: 50%;width: 250px;transition: 0.5s all ease-in;opacity: 1;
  }
  100% {
    top:60%; left: 50%;width: 350px;transition: 0.5s all ease-in;opacity: 1;
  }
}

@keyframes cir_inner {

0%{
rotate: 0deg;
}
100%{
rotate: 360deg;
}

}

@keyframes circle_1 {
  from {
    bottom: 5%;
    left: 40%;
    z-index: 0;
    opacity: 0;
  }
  to {
    bottom: 5%;
    left: 24%;
    z-index: 1;
    opacity: 1;
  }
}

@keyframes circle_2 {
  from {
    top: 45%;
    left: 44%;
    z-index: 0;
    opacity: 0;
  }
  to {
    top: 25%;
    left: 28%;
    z-index: 1;
    opacity: 1;
  }
}

@keyframes circle_3 {
  from {
    top: 30%;
    z-index: 0;
    opacity: 0;
  }
  to {
    top: 5%;
    z-index: 1;
    opacity: 1;
  }
}

@keyframes cricle_5 {
  from {
    bottom: 5%;
    right: 40%;
    z-index: 0;
    opacity: 0;
  }
  to {
    bottom: 5%;
    right: 24%;
    z-index: 1;
    opacity: 1;
  }
}

@keyframes circle_4 {
  from {
    top: 45%;
    right: 44%;
    z-index: 0;
    opacity: 0;
  }
  to {
    top: 25%;
    right: 28%;
    z-index: 1;
    opacity: 1;
  }
}

.itemside img.img-sm {
  width: 150px;
  height: 150px;
}
/* body {
  background-color: #e0e3e8;
  font-family: "Rubik", sans-serif;
} */
.form-area{
  background-color: #fff;
  box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
  padding: 40px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-area .form-inner{
width: 100%;
}
.text-primary{
color : #5a8dee !important;
}
.btn-primary {
  border-color: #5a8dee !important;
  background-color: #5a8dee !important;
  color: #fff;
}
.btn{
padding: 10px 30px;
font-size: 15px;
letter-spacing: 0.3px;
font-weight: 400;
}
.btn-sm{
padding: 10px 25px;
font-size: 14px;
}

.qty_btn .btn{
padding: 5px !important;
}
.table-shopping-cart tbody tr td{
vertical-align: middle;
}

@media (max-width: 992px) {
  .list_box {
    margin-bottom: 20px;
  }
  .sec_title h4 {
    font-size: 20px;
  }
  .social_icons {
    margin-top: 20px;
  }
  .cart_icon{
    padding: 10px
  }
}

@media (max-width: 768px) {
  .download_icon {
    margin-top: 20px;
  }
}










